import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('../views/index.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/about.vue')
    },
    {
      path: '/policy',
      name: 'policy',
      component: () => import('../views/policy.vue')
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('../views/faq.vue')
    },
  ]
})

