<template>
    <div class="dialog">
        <el-dialog
        class="web"
        title=""
        :visible.sync="dialogVisible"
        width="540px"
        :show-close="false"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <div class="pic"></div>
        <img class="bg" src="../../assets/dialog/Pop-up_bg@2x.png" />
        <img class="close" src="../../assets/form/close@2x.png" alt="close" @click="handleClose">
        <div class="title">APPLY TO SELL WITH KNET</div>
        <el-form :model="form" :rules="rules" ref="form" class="form">
            <el-form-item prop="name" class="username">
                <el-input v-model="form.name" placeholder="Name" >
                    <img class="img_pre" slot="prefix" src="../../assets/form/name@2x.png" alt="">
                </el-input>
            </el-form-item>
            <el-form-item prop="email" class="username">
                <el-input v-model="form.email" placeholder="Email" >
                    <img class="img_pre" slot="prefix" src="../../assets/form/email@2x.png" alt="">
                </el-input>
            </el-form-item>
            <el-form-item prop="phone" class="username">
                <el-input v-model="form.phone" placeholder="Phone Number" >
                    <img class="img_pre" slot="prefix" src="../../assets/form/Phone@2x.png" alt="">
                </el-input>
            </el-form-item>
            <el-form-item prop="monthlyVolume" class="username">
                <el-input v-model="form.monthlyVolume" placeholder="Current Monthly Sales Volume" >
                    <img class="img_pre" slot="prefix" src="../../assets/form/sales volume@2x.png" alt="">
                </el-input>
            </el-form-item>
            <el-form-item prop="ins" class="username">
                <el-input v-model="form.instagramHandle" placeholder="Instagram Handle (Optional)" >
                    <img class="img_pre" slot="prefix" src="../../assets/form/ins@2x.png" alt="">
                </el-input>
            </el-form-item>
            <el-form-item prop="ask" class="username">
                <el-input v-model="form.askUs" placeholder="Other (Optional)" type="textarea"></el-input>
            </el-form-item>
        </el-form>
        <div class="btn" @click="submitBtn('form')">APPLY TO SELL</div>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span> -->
        </el-dialog>

        <el-dialog
            class="web"
            title=""
            :visible.sync="dialogVisiblesuccess"
            :show-close="false"
            width="540px"
            :close-on-click-modal="false"
        >
            <img class="bg" src="../../assets/dialog/Pop-up_bg@2x.png" />
            <img class="close" src="../../assets/form/close@2x.png" alt="close" @click="handleClose">
            <div class="tips_wrap">
                <div class="pic1"></div>
                <div class="title1">HANG TIGHT!</div>
                <div class="title2">Our team has successfully received your application. A dedicated representative will be in touch shortly to finalize your setup.</div>

            </div>
        </el-dialog>

        <el-dialog
        class="phone"
        title=""
        :visible.sync="dialogVisible"
        width="360px"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <div class="pic"></div>
        <div class="title">APPLY TO SELL WITH KNET</div>
        <el-form :model="form" :rules="rules" ref="form" class="form">
            <el-form-item prop="name" class="username">
                <el-input v-model="form.name" placeholder="Name" >
                    <img class="img_pre" slot="prefix" src="../../assets/form/name@2x.png" alt="">
                </el-input>
            </el-form-item>
            <el-form-item prop="email" class="username">
                <el-input v-model="form.email" placeholder="Email" >
                    <img class="img_pre" slot="prefix" src="../../assets/form/email@2x.png" alt="">
                </el-input>
            </el-form-item>
            <el-form-item prop="phone" class="username">
                <el-input v-model="form.phone" placeholder="Phone Number" >
                    <img class="img_pre" slot="prefix" src="../../assets/form/Phone@2x.png" alt="">
                </el-input>
            </el-form-item>
            <el-form-item prop="monthlyVolume" class="username">
                <el-input v-model="form.monthlyVolume" placeholder="Current Monthly Sales Volume" >
                    <img class="img_pre" slot="prefix" src="../../assets/form/sales volume@2x.png" alt="">
                </el-input>
            </el-form-item>
            <el-form-item prop="ins" class="username">
                <el-input v-model="form.instagramHandle" placeholder="Instagram Handle (Optional)" >
                    <img class="img_pre" slot="prefix" src="../../assets/form/ins@2x.png" alt="">
                </el-input>
            </el-form-item>
            <el-form-item prop="ask" class="username">
                <el-input v-model="form.askUs" placeholder="Ask Us (Optional)" type="textarea"></el-input>
            </el-form-item>
        </el-form>
        <div class="btn" @click="submitBtn('form')">APPLY TO SELL</div>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span> -->
        </el-dialog>
        <el-dialog
            class="phone"
            title=""
            :visible.sync="dialogVisiblesuccess"
            :show-close="false"
            width="300px"
            :close-on-click-modal="false"
        >
            <img class="bg" src="../../assets/dialog/Pop-up_bg@2x.png" />
            <img class="close" src="../../assets/form/close@2x.png" alt="close" @click="handleClose">
            <div class="tips_wrap">
                <div class="pic1"></div>
                <div class="title1">HANG TIGHT!</div>
                <div class="title2">Our team has successfully received your application. A dedicated representative will be in touch shortly to finalize your setup.</div>

            </div>
        </el-dialog>
    </div>
    
</template>

<script>
import axios from 'axios'
export default {
    name: 'FooterPage',
    components: {
    },
    props: {
        showvisible: {
            type: Boolean,
            default: false
        },
    },


    data() {
        return {
            dialogVisible:false,
            dialogVisiblesuccess: false,
            form: {
                name: '',
                askUs: '',
                email: '',
                instagramHandle: '',
                monthlyVolume: '',
                phone: '',
            },
            rules: {
                name: [
                    { required: true, message: '*Please enter your name', trigger: 'blur' }
                ],
                email: [
                    { required: true, message: '*Please enter a valid email', trigger: 'blur' },
                    { pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: '*Please enter a valid email', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '*Please enter your phone number', trigger: 'blur' }
                ],
                monthlyVolume: [
                    { required: true, message: '*Please enter your monthly sales volume', trigger: 'blur' }
                ],
            }
        }
    },
    computed: {

    },
    watch: {
        showvisible: function ( newval,old ) {
            console.log(888,newval,old)
            if(newval) {
                this.dialogVisible = true;
            }
        }
    },
    mounted() {
        
    },
    methods: {
        open (url) {
            window.open(url, '_blank')
        },
        sendEmail () {
            const email = 'support@knetgroup.com';
            window.location.href = `mailto:${email}`;
        },
        submitBtn(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    axios.post('https://admin-api.knetgroup.com/official_website/applyToKnet',this.form).then(()=>{
                    })
                    this.$emit("closeDialog",false)
                    this.dialogVisible = false;
                    this.dialogVisiblesuccess = true;
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleClose() {
            this.$emit("closeDialog",false)
            this.dialogVisible = false
            this.dialogVisiblesuccess = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.tips_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 80px 80px;
}
/deep/ .el-dialog__wrapper {
    background: rgba(0, 0, 0, .7);
    z-index:2999;
}
/deep/ .el-dialog{
    position: relative;
    margin: 0 auto 50px;
    background: #000;
    border-radius: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    border: 1px solid rgba(255,255,255,0.2);
}
/deep/ .el-input__inner {
    background: #000;
    color: #C9D4CD;
    padding-left: 50px  !important;
    border: 1px solid #5A6E62;
    border-radius: 10px;
}
/deep/.el-textarea__inner {
    font-family: LINESeedSans_regular;
    background: #000;
    color: #C9D4CD;
    border: 1px solid #5A6E62;
    border-radius: 10px;
}
/deep/ .el-textarea__inner {
    background: #000;
    border: 1px solid #5A6E62; 
}
/deep/ .el-input__inner::placeholder {
    color: var(--placeholder-color, #5B6C62); /* 标准浏览器 */
    font-weight: 400;
    font-size: 14px;
    color: #5B6C62;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
/deep/ .el-textarea__inner::placeholder {
    color: var(--placeholder-color, #5B6C62); /* 标准浏览器 */
    font-weight: 400;
    font-size: 14px;
    color: #5B6C62;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
/deep/ .el-input__inner:focus {
    border-color: #67DD89; /* 这里设置为你想要的颜色 */
}
/deep/ .el-textarea__inner:focus {
    border-color: #67DD89; /* 这里设置为你想要的颜色 */
}
.pic {
    width: 230px;
    height: 148px;
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    background: url(../../assets/dialog/Pop-up_img@2x.png) no-repeat;
    background-size: contain;
    z-index: 2;
}
.bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 540px;
    border-radius: 20px;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.pic1 {
    width: 140px;
    height: 108px;
    background: url(../../assets/home_slices/done@2x.png) no-repeat;
    background-size: contain;
}
.title {
    font-family: LINESeedSans_Xbd;
    font-weight: 800;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 38px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin-top:30px;
}
.title1 {
    font-family: LINESeedSans_Xbd;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 38px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin-top: 40px;
}
.title2 {
    width: 380px;
    font-weight: 400;
    font-size: 16px;
    color: #C9D4CD;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin-top: 24px;
    word-break: keep-all;
}
.form {
    padding-left: 40px;
    padding-right: 40px;
    .username {
        margin-top: 20px;
    }
    .img_pre {
        width: 24px;
        height: 24px;
        margin: 8px 10px;
    }
}
.btn {
    background: linear-gradient( 225deg, #66F47F 0%, #93F466 100%);
    border-radius: 10px 10px 10px 10px;
    border: 0px solid rgba(255,255,255,0.2);
    height: 48px;
    line-height: 48px;
    text-align: center;
    margin-left: 40px;
    margin-right: 40px;
    box-sizing: border-box;
    font-family: LINESeedSans_Xbd;
    font-size: 16px;
    color: #222222;
    text-align: center;
    font-style: normal;
    text-transform: none;
    cursor: pointer;
}

@media (max-width: 576px) {
    /deep/ .el-dialog__wrapper {
        background: #000;
        z-index:2999;
    }
    /deep/ .el-dialog{
        position: relative;
        margin: 0 auto 50px;
        background: url(../../assets/dialog/Pop-up_bg@2x.png) no-repeat;
        background-size: contain;
        border-radius: 20px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
        box-sizing: border-box;
    }
    /deep/ .el-dialog__body {
        padding: 30px 10px;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
    }
    /deep/ .el-input__inner {
        background: #000;
        color: #C9D4CD;
        border: 1px solid #5A6E62;
    }
    /deep/ .el-textarea__inner {
        background: #000;
        border: 1px solid #5A6E62; 
    }
    /deep/ .el-input__inner::placeholder {
    color: var(--placeholder-color, #5B6C62); /* 标准浏览器 */
    }
    /deep/ .el-textarea__inner::placeholder {
    color: var(--placeholder-color, #5B6C62); /* 标准浏览器 */
    }
    /deep/ .el-input__inner:focus {
    border-color: #67DD89; /* 这里设置为你想要的颜色 */
    }
    /deep/ .el-textarea__inner:focus {
    border-color: #67DD89; /* 这里设置为你想要的颜色 */
    }
    .tips_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 10px;
}
    .pic {
        width: 168px;
        height: 108px;
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
        background: url(../../assets/dialog/Pop-up_img@2x.png) no-repeat;
        background-size: contain;
    }
    .pic1 {
        width: 140px;
        height: 108px;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        background: url(../../assets/home_slices/done@2x.png) no-repeat;
        background-size: contain;
    }
    .title {
        font-family: LINESeedSans_Xbd;
        font-weight: 800;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 23px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-top:30px;
    }
    .title1 {
        font-family: LINESeedSans_Xbd;
        font-weight: 800;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 23px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-top:120px;
    }
    .title2 {
        width: 240px;
        font-size: 14px;
        color: #C9D4CD;
        line-height: 24px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-top: 24px;
    }
    .form {
        padding-left: 24px;
        padding-right: 24px;
        .username {
            margin-top: 20px;
        }
    }
    .btn {
        background: linear-gradient( 225deg, #66F47F 0%, #93F466 100%);
        border-radius: 10px 10px 10px 10px;
        border: 0px solid rgba(255,255,255,0.2);
        height: 48px;
        line-height: 48px;
        text-align: center;
        margin-left: 40px;
        margin-right: 40px;
        box-sizing: border-box;
        font-family: LINESeedSans_Xbd;
        font-weight: 800;
        font-size: 16px;
        color: #222222;
        text-align: center;
        font-style: normal;
        text-transform: none;
    }
    .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 300px;
    border-radius: 20px;
}
.close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
}
</style>