<template>
  <div id="app">
    <Nav />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Nav from './components/Nav/index.vue'
import Footer from './components/Footer/index.vue'

export default {
  name: 'App',
  components: {
    Nav, Footer
  }
}
</script>

<style>
.v-modal {
  z-index:1888 !important;
}
</style>
