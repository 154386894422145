<template>
    <div class="footer">
        <div class="top container">
            <div class="menu">
                <div class="item">
                    <div>INFORMATION</div>
                    <p ><span class="text_hover" @click="$router.push('/about')" >About Us</span></p>
                    <p class="mg-t16 "><span class="text_hover" @click="sendEmail" >Contact Us</span></p>
                </div>
                <div class="item">
                    <div>LEGAL</div>
                    <p class="text_hover" @click="$router.push('/policy')"><span class="text_hover" @click="$router.push('/faq')">Privacy Policy</span></p>
                    <p class="mg-t16 "><span class="text_hover" @click="$router.push('/faq')">FAQ</span></p>
                </div>
            </div>
            <div class="media">
                <div>SOCIAL MEDIA</div>
                <div class="icon">
                    <img class="img_btn_hover" @click="open('https://discord.gg/ZdnnJXzk5F')" src="@/assets/home_slices/discord@2x.png" alt="">
                    <img class="img_btn_hover" @click="open('https://www.instagram.com/knetgroup/')" src="@/assets/home_slices/ins@2x.png" alt="">
                </div>
                <!-- <div class="title-btn">
                    <div @click="sendEmail">Contact Us</div>
                    <div @click="open('https://supply.knetgroup.com/')" class="liner-border">Seller Entry</div>
                </div> -->
            </div>
        </div>
        <div class="desc container">
            <img class="logo-img" src="@/assets/home_slices/logo@2x.png" alt="" @click="goHomeTop">
            Copyright © 2024 KNETGROUP Company S.L. All rights reserved.
        </div>

        <Dialog></Dialog>
    </div>
</template>

<script>
import Dialog from '../Dialog/index.vue'
export default {
    name: 'FooterPage',
    components: {
        Dialog
    },

    data() {
        return {
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {
        open (url) {
            window.open(url, '_blank')
        },
        sendEmail () {
            const email = 'support@knetgroup.com';
            window.location.href = `mailto:${email}`;
        },
        goHomeTop () {
            if (this.$route.name !== 'index') {
                this.$router.replace('/')
            }
            window.scrollTo({top: 0})
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    box-sizing: border-box;
    width: 100%;
    background: #101113;
    text-align: left;
    z-index: 2000;

    .mg-t16 {
        margin-top: 16px;
    }

    .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(77, 77, 77, .38);
        padding: 40px 0;
        flex-wrap: wrap;

        .menu {
            flex: 1;
            display: flex;
            gap: 40px;
            white-space: nowrap;

            .item {
                font-size: 14px;
                color: rgba(255, 255, 255, .6);
                flex: 1;
                span {
                    cursor: pointer;
                }
                div:nth-child(1) {
                    font-family: LINESeedSans;
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 16px;
                    color: #FFFFFF;
                }
            }

        }

        .media {
            text-align: left;
            font-family: LINESeedSans;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 21px;
            text-align: left;

            .icon {
                margin-top: 16px;
                display: flex;
                align-items: center;
                gap: 24px;
                cursor: pointer;

                img {
                    display: block;
                    width: 48px;
                    height: 48px;
                    object-fit: cover;
                }
            }

            .title-btn {
                display: flex;
                align-items: center;
                gap: 24px;
                margin-top: 40px;

                div {
                    cursor: pointer;
                    font-family: LINESeedSans;
                    font-size: 16px;
                    line-height: 21px;
                    text-align: center;
                    padding: 14px 22px;
                    border-radius: 4px;
                }

                div:nth-child(1) {
                    color: #222222;
                    background: linear-gradient(225deg, #66F47F 0%, #93F466 100%);
                    border: 2px solid;
                }
            }
        }
    }

    .desc {
        padding: 40px 0;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: rgba(255, 255, 255, .6);
        line-height: 19px;

        .logo-img {
            cursor: pointer;
            width: 226px;
            height: 40px;
            margin-right: 24px;
        }
    }

}

@media (max-width: 576px) {
    .footer {

        .top {
            flex-direction: column;
            gap: 40px;
        }

        .desc {
            padding: 24px 0;
            flex-direction: column;
            align-items: flex-start;
            font-size: 12px;
            line-height: 16px;
            gap: 16px;

            .logo-img {
                width: 136px;
                height: 24px;
                // margin-right: 24px;
            }
        }
    }
}
</style>