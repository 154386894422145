<template>
    <div class="top-nav">
        <img class="logo-img" src="@/assets/home_slices/logo@2x.png" alt="" @click="$router.push('/')">
        <div class="menu">
            <div class="item top_text_hover" v-for="(item, index) in menuOptions" :key="index"
                :class="item.path == $route.path ? 'active' : ''" @click="toPage(item.path)">
                {{ item.name }}
            </div>
            <div @click="open('https://supply.knetgroup.com/')" class="liner-border">SELLER ENTRY</div>
            <div class="btn btn_hover" @click="showdialog">APPLY TO SELL</div>
        </div>
        <div class="phone-menu">
            <img class="img-menu" src="@/assets/home_slices/more@2x.png" alt="" @click="show = true">
            <div class="drop-down" v-if="show">
                <div class="item" v-for="(item, index) in menuOptions" :key="index"
                    :class="item.path == $route.path ? 'active' : ''" @click="toPage(item.path)">
                    {{ item.name }}
                </div>
                <div @click="open('https://supply.knetgroup.com/')" class="liner-border wt-107">SELLER ENTRY</div>
                <div class="btn  wt-107" @click="showdialog">APPLY TO SELL</div>
            </div>

        </div>
        <Dialog :showvisible="showvisible" @closeDialog="changevisible"></Dialog>
    </div>
</template>

<script>
import Dialog from '../Dialog/index.vue'

export default {
    name: 'NavPage',
    components: {
        Dialog
    },

    data() {
        return {
            showvisible:false,
            show: false,
            // path: $route.path,
            menuOptions: [
                { name: 'Home', path: '/' },
                { name: 'About Us', path: '/about' },
                { name: 'FAQ', path: '/faq' },
            ]
        }
    },
    computed: {
    },
    watch: {
    },
    mounted() {
        //点击任意位置关闭区域弹窗
        document.addEventListener('click', (e) => {
            //获取弹窗对象
            const userCon = document.getElementsByClassName('phone-menu')[0];
            const userCon1 = document.getElementsByClassName('drop-down')[0];
            const userCon2 = document.getElementsByClassName('img-menu')[0];

            //判断弹窗对象中是否包含点击对象
            if (!e.target.contains(userCon) && !e.target.contains(userCon1) && !e.target.contains(userCon2)) {
                this.show = false
            }
        })

    },
    methods: {
        open (url) {
            window.open(url, '_blank')
        },
        toPage(path) {
            this.$router.push(path, () => { }, () => { });
        },
        sendEmail () {
            const email = 'support@knetgroup.com';
            window.location.href = `mailto:${email}`;
        },
        showdialog() {
            this.showvisible = true;
        },
        changevisible(v) {
            this.showvisible = v;
        }
    }
}
</script>

<style lang="scss" scoped>
.wt-107 {
    width: 107px;
}
.top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.43% 7.64%;
    width: 100%;
    height: 68px;
    margin-bottom: 12px;
    background: #040B11;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1999;
    box-sizing: border-box;
    box-sizing: border-box;

    .logo-img {
        width: 226px;
        height: 40px;
        cursor: pointer;
    }

    .menu {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 40px;
        white-space: nowrap;
    }

    .btn {
        cursor: pointer;
        padding: 10px 21px;
        color: #222222;
        background: linear-gradient(225deg, #66F47F 0%, #93F466 100%);
        border-radius: 4px;
        font-family: LINESeedSans;
    }

    .item {
        cursor: pointer;
        line-height: 19px;
    }

    .active {
        background-image: linear-gradient(134.99999999999986deg, #66F47F 0%, #93F466 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: LINESeedSans;
    }

    .phone-menu {
        display: none;
        position: absolute;
        right: 10px;

        .img-menu {
            width: 24px;
            height: 24px;
            object-fit: contain;
        }

        .drop-down {
            position: absolute;
            right: 0;
            top: 32px;
            background: rgba(20, 22, 25, 0.5);
            border-radius: 2px;
            border: 1px solid rgba(255, 255, 255, 0.17);
            backdrop-filter: blur(10px);
            padding: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: flex-start;
            white-space: nowrap;
            /* 设置过渡效果 */
            transition: 1s;
        }


    }

}

@media (max-width: 576px) {

    .top-nav {
        padding: 16px 24px;
        height: 56px;

        .logo-img {
            width: 136px;
            height: 24px;
        }

        .menu {
            display: none;
        }

        .phone-menu {
            display: block;
            width: 24px;
            height: 24px;
            object-fit: contain;
        }
    }
}

.liner-border {
    color: #7DF472;
    position: relative;
    border-radius: 4px;
    padding: 10px 21px;
    cursor: pointer;
}
.liner-border:hover {
    color: #38ec27;
    background: linear-gradient(225deg, #45454554 0%, #2222226e 100%);
}

.liner-border::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 2px;
    box-sizing: border-box;
    border-radius: 4px;
    background: linear-gradient(225deg, #66F47F 0%, #93F466 100%);
    /* 随便定义一个颜色 */
    --mask-bg: linear-gradient(red, red);
    --mask-clip: content-box, padding-box;
    -webkit-mask-image: var(--mask-bg), var(--mask-bg);
    -webkit-mask-clip: var(--mask-clip);
    /* exclude排除，只显示不重合的地方,Firefox支持4个属性 */
    mask-composite: exclude;
    /* 只显示下方遮罩，重合的地方不显示 */
    -webkit-mask-composite: destination-out;
}
</style>